import { FETCH_CITY_FAILED, FETCH_CITY_RESPONSE, FETCH_COUNTRY_FAILED, FETCH_COUNTRY_RESPONSE, FETCH_MASTER_FAILED, FETCH_MASTER_RESPONSE } from "../types/vendorType";

const initialState = {
    countrys: [],
    states: [],
    businessType: [],
    salutation: [],
    vendorType: [],
    city: [],
}
const  reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COUNTRY_RESPONSE :
            const {country, state: states} = action.payload.data
            return {...state, countrys:country, states }
        case FETCH_COUNTRY_FAILED:
            return {...state, countrys: [], states: [] }
        case FETCH_MASTER_RESPONSE :
            const {businessType, salutation, vendorType} = action.payload.data
            return {...state, businessType, salutation, vendorType }
        case FETCH_MASTER_FAILED:
            return {...state, businessType:[], salutation: [], vendorType:[] }
        case FETCH_CITY_RESPONSE :
            const {data} = action.payload
            return {...state, city:data }
        case FETCH_CITY_FAILED:
            return {...state, city:[] }
        default:
            return state
    }
}

export default reducer