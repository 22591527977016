export const FETCH_COUNTRY = "FETCH_COUNTRY"
export const FETCH_COUNTRY_RESPONSE = "FETCH_COUNTRY_RESPONSE"
export const FETCH_COUNTRY_FAILED = "FETCH_COUNTRY_FAILED"


export const FETCH_CITY = "FETCH_CITY"
export const FETCH_CITY_RESPONSE = "FETCH_CITY_RESPONSE"
export const FETCH_CITY_FAILED = "FETCH_CITY_FAILED"


export const ADD_VENDOR = "ADD_VENDOR"
export const ADD_VENDOR_RESPONSE = "ADD_VENDOR_RESPONSE"
export const ADD_VENDOR_FAILED = "ADD_VENDOR_FAILED"


export const FETCH_MASTER = "FETCH_MASTER"
export const FETCH_MASTER_RESPONSE = "FETCH_MASTER_RESPONSE"
export const FETCH_MASTER_FAILED = "FETCH_MASTER_FAILED"